/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // JavaScript to be fired on the home page, after the init JS
        var $form = $('form.cart');
        //var subscription_val = 'no';
        //input = $('.wcsatt-options-prompt-action-input');
        var subscription_val = 'no';
        //$(".reset_variations").trigger("click");
        function getRadioValue() {
          subscription_val = $('form.cart .wcsatt-options-prompt-action-input:checked').val();
          //console.log(subscription_val);
          if(subscription_val === 'yes') {
            return false;
          } else {
            return true;
          }
        }
        function refreshMinicart() {
          var data = {
            action: 'bpd_ajax_refresh_minicart'
          };
          $.ajax({
            contentType: "application/x-www-form-urlencoded; charset=UTF-8", // $_POST
            method: 'POST',
            url: sitesettings.ajaxurl,
            type: 'JSON',
            data: data,
            beforeSend: function (response) {
              //  $thisbutton.removeClass('added').addClass('loading');
            },
            complete: function (response) {
              //  $thisbutton.addClass('added').removeClass('loading');
            },
            success: function (response) {
              $('.cart-contents-count').html(response.total);
              $('.cart-list-inner').html(response.slideincartcontent);
            },
          });
        }
        refreshMinicart();
        var timeout;
        // refresh cart automatically after timeout and refresh minicart
        $('.woocommerce').on('change', 'input.qty', function(){
          if ( timeout !== undefined ) {
            clearTimeout( timeout );
          }
          timeout = setTimeout(function() {
            $("[name='update_cart']").trigger("click");
            timeout = setTimeout(function() {
              refreshMinicart();
            }, 750 );
          }, 750 ); // 1 second delay, half a second (500) seems comfortable too
        });
        $(document).on('click', '.single_add_to_cart_button:not(.disabled)', function (e) {
          //console.log(subscription_val);
          if(getRadioValue()) {
            e.preventDefault();
            $thisbutton = $(this);
            var product_qty = $form.find('input[name=quantity]').val() || 1;
            var qty_max = parseInt($form.find('input[name=quantity]').attr('max'));
            if(product_qty > qty_max){
              product_qty = qty_max;
            }
            product_id = $form.find('input[name=product_id]').val() || $thisbutton.val() || 0;
            variation_id = $form.find('input[name=variation_id]').val() || 0;
            //console.log(product_id);
            //console.log(product_qty);
            //console.log(variation_id);
            var data = {
              product_id: product_id,
              product_sku: '',
              quantity: product_qty,
              variation_id: variation_id,
              //subscription_val: subscription_val,
              action: 'woocommerce_ajax_add_to_cart'
            };
            $(document.body).trigger('adding_to_cart', [$thisbutton, data]);
            $.ajax({
              contentType: "application/x-www-form-urlencoded; charset=UTF-8", // $_POST
              method: 'POST',
              url: sitesettings.ajaxurl,
              type: 'JSON',
              data: data,
              beforeSend: function (response) {
                $thisbutton.removeClass('added').addClass('loading');
              },
              complete: function (response) {
                $thisbutton.addClass('added').removeClass('loading');
              },
              success: function (response) {
                //console.log(response);
                $('.custom-message-wrapper p').html(response.message);
                $( ".custom-message-wrapper" ).addClass('active');
                setTimeout(function(){
                  $( ".custom-message-wrapper" ).removeClass('active');
                }, 3000);
                refreshMinicart();
                //  $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, $thisbutton]);
              },
            });
          }
        });
        $(document).on('click', '.remove-product', function (e) {
          e.preventDefault();
          $thisbutton = $(this);
          var  product_id = $thisbutton.data('product_id');
          cart_item_key = $(this).attr("data-cart_item_key"),
            product_container = $(this).parents('.cart-item');
          //variation_id = $form.find('input[name=variation_id]').val() || 0;
          //console.log(product_id);
          //console.log(product_qty);
          //console.log(variation_id);
          var data = {
            product_id: product_id,
            cart_item_key: cart_item_key,
            action: 'woocommerce_ajax_remove_from_cart'
          };
          $.ajax({
            contentType: "application/x-www-form-urlencoded; charset=UTF-8", // $_POST
            method: 'POST',
            url: sitesettings.ajaxurl,
            type: 'JSON',
            data: data,
            beforeSend: function (response) {
              //  $thisbutton.removeClass('added').addClass('loading');
            },
            complete: function (response) {
              //  $thisbutton.addClass('added').removeClass('loading');
            },
            success: function (response) {
              $('.custom-message-wrapper p').text(response.message);
              $( ".custom-message-wrapper" ).addClass('active');
              setTimeout(function(){
                $( ".custom-message-wrapper" ).removeClass('active');
              }, 3000);
              product_container.remove();
              refreshMinicart();
            },
          });
        });
        $('.slide-in-trigger').bind('click touchstart', function(event) {
          event.preventDefault();
          $(this).toggleClass('active');
          var targetstring = $(this).attr('data-target');
          var target = $('#' + targetstring);
          $('body').toggleClass('slide-in-visible' + ' ' + targetstring + '-visible');
          target.addClass('active');
          if($(this).hasClass('mobile-nav-trigger')){
            if($('.nav-primary-mobile .mobile-parent').hasClass('active')) {
              $('.nav-primary-mobile .mobile-parent').removeClass('active');
              $('.nav-primary-mobile .mobile-parent').find('.sub-menu').removeClass('active').slideUp();
            }
          }
          //$('.mobile-nav-wrapper').toggleClass('active');
        });
        function closeMobileNav(targetstring) {
          var closetargetstring = targetstring;
          var target = $('#' + closetargetstring);
          $('body').removeClass('slide-in-visible' + ' ' + closetargetstring + '-visible');
          target.removeClass('active');
          $('.slide-in-trigger').removeClass('active');
        }
        if(window.innerWidth > 1024) {
          closeMobileNav();
        }
        $(window).resize(function(){
          if(window.innerWidth > 1024) {
            setTimeout(function() {
              closeMobileNav();
            }, 100);
          }
        });
        var canclickmenuitem = true;
        $('.nav-mobile-bottom .menu-item-has-children > a').bind('click touchstart', function (event) {
          event.preventDefault();
          if (canclickmenuitem) {
            canclickmenuitem = false;
            $(this).parent().toggleClass('active').children('.sub-menu').toggleClass('active').slideToggle(300, function() {
              // Animation complete.
              canclickmenuitem = true;
            });
          }
        });
        $('.slide-in-close').bind('click touchstart', function(event) {
          event.preventDefault();
          var targetstring = $(this).attr('data-target');
          closeMobileNav(targetstring);
        });
        $('.banner-carousel').slick({
          arrows: true,
          dots: false,
          speed: 1000,
          autoplay: true,
          prevArrow: $('.carousel-left'),
          nextArrow: $('.carousel-right')
        });
        $('.discount-carousel').slick({
          arrows: true,
          dots: true,
          speed: 1000,
          appendDots: '.discount-carousel-dots',
          prevArrow: $('.discount-carousel-left'),
          nextArrow: $('.discount-carousel-right'),
          customPaging: function() {
            return '<button class="wide-carousel-dot"></button>';
          },
        });
        $('.new-products-carousel').slick({
          arrows: true,
          dots: false,
          speed: 1000,
          infinite: true,
          slidesToScroll: 4,
          slidesToShow: 4,
          prevArrow: $('.new-products-carousel-left'),
          nextArrow: $('.new-products-carousel-right'),
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
        $('.popular-products-carousel').slick({
          arrows: true,
          dots: false,
          speed: 1000,
          infinite: true,
          slidesToScroll: 4,
          slidesToShow: 4,
          prevArrow: $('.popular-products-carousel-left'),
          nextArrow: $('.popular-products-carousel-right'),
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
        $('.custom-tab').click(function(){
          var tabcontent = $('.tab-content');
          var target = $(this).attr('data-target');
          if(! $(this).hasClass('active')){
            $('.custom-tab').removeClass('active');
            $(this).addClass('active');
            tabcontent.removeClass('active');
            console.log(target);
            $(target).addClass('active');
          }
        });
      }
    },
    // cart page
    'woocommerce_cart': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('.woocommerce').on('click', '.remove', function (e) {
          e.preventDefault;
          $(this).prev().find('.qty').val(0).attr('value', 0).trigger('change');
        });
        $('.woocommerce').on('click', '.quantity-down', function (e) {
          currentval = parseInt($(this).parent().find('.qty').val());
          maxval = parseInt($(this).parent().find('.qty').attr('max'));
          newval = currentval - 1;
          if(currentval > 0 && currentval <= maxval) {
            $(this).parent().find('.qty').val(newval).attr('value', newval).trigger('change');
          }
        });
        $('.woocommerce').on('click', '.quantity-up', function (e) {
          currentval = parseInt($(this).parent().find('.qty').val());
          maxval = parseInt($(this).parent().find('.qty').attr('max'));
          newval = currentval + 1;
          if(currentval > 0 && currentval < maxval) {
            $(this).parent().find('.qty').val(newval).attr('value', newval).trigger('change');
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // single product
    'single_product': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        $('.woocommerce').on('click', '.minus', function (e) {
          currentval = parseInt($(this).parent().find('.qty').val());
          maxval = parseInt($(this).parent().find('.qty').attr('max'));
          newval = currentval - 1;
          if(currentval > 0 && currentval <= maxval) {
            $(this).parent().find('.qty').val(newval).attr('value', newval).trigger('change');
          }
        });
        $('.woocommerce').on('click', '.plus', function (e) {
          currentval = parseInt($(this).parent().find('.qty').val());
          maxval = parseInt($(this).parent().find('.qty').attr('max'));
          newval = currentval + 1;
          if(currentval >= 0 && currentval < maxval ) {
            $(this).parent().find('.qty').val(newval).attr('value', newval).trigger('change');
          }
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'news': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        var wrapper = $('.articles-wrapper');
        $('.loadmore').on('click', function(e){
          e.preventDefault();
          var button = $(this);
          var $offset = button.attr('data-offset');
          wrapper.addClass('loading');
          $.ajax({
            method: 'POST',
            url: sitesettings.ajaxurl,
            type: 'JSON',
            data: {
              offset: $offset,
              action: 'ajax_load_posts'
            },
            success:function(response){
              console.log(response);
              $('.articles-wrapper').append(response.data.post);
              $('.loadmore').attr('data-offset', parseInt(response.data.offset));
              wrapper.removeClass('loading');
              if(response.data.num < 6) {
                button.parent().remove();
                wrapper.removeClass('loading');
              }
            }
          });
        });
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
